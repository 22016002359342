import React from "react";
import {Button, Form, Input, Spin, Typography} from "antd";
import * as Setting from "../Setting";
import i18next from "i18next";
import * as AgreementModal from "../common/modal/AgreementModal";
import {SendCodeInput, VerifyMethod, VerifyType} from "../common/SendCodeInput";
import {withRouter} from "react-router-dom";
import {CountryCodeSelect} from "../common/select/CountryCodeSelect";
import {Require, UserItem} from "../UserItem";
import * as UserBackend from "../backend/UserBackend";

const {Title, Text} = Typography;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 10,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 14,
    },
  },
};

export const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 4,
    },
  },
};

const formItems = [
  {name: UserItem.DisplayName, visible: true, required: true, rule: "None"},
  {name: UserItem.FirstName, visible: true, required: true, rule: "None"},
  {name: UserItem.LastName, visible: true, required: true, rule: "None"},
  {name: UserItem.MiddleName, visible: true, required: true, rule: "None"},
  {name: UserItem.Email, visible: true, required: true, rule: "Normal"},
  {name: UserItem.Phone, visible: true, required: true, rule: "None"},
  {name: UserItem.Agreement, visible: true, required: true, rule: "None"},
];

// Компонент формы внесения дополнительных данных пользователем
class ExtendProfileForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organizationName: props.accountOwner,
      userName: props.accountName,
      application: props.application,
      user: null,
      email: "",
      phone: "",
      countryCode: "",
      emailCode: "",
      phoneCode: "",
      validEmail: false,
      validPhone: false,
      isTermsOfUseVisible: false,
      termsOfUseContent: "",
      require: props.require, // Набор обязательных параметров которые приложение запрашивает при регистрации в системе.
      loading: true,
      errorDisplayName: "",
      errorEmail: "",
      errorPhone: "",
    };

    this.form = React.createRef();
  }

  UNSAFE_componentWillMount() {
    this.getUser();
  }

  getUser() {
    UserBackend.getUser(this.state.organizationName, this.state.userName)
      .then((res) => {
        if (res.data === null) {
          this.props.history.push("/404");
          return;
        }

        if (res.status === "error") {
          Setting.showMessage("error", res.msg);
          return;
        }

        this.setState({
          user: res.data,
        });
      });
  }

  getApplicationObj() {
    return this.state.application;
  }

  onFinish(values) {
    const user = {...this.state.user, ...values};
    this.props.onFinish(user);
  }

  onFailed(values, errorFields, outOfDate) {
    this.form.current.scrollToField(errorFields[0].name);
  }

  renderFormItem(application, formItem) {
    if (!formItem.visible) {
      return null;
    }

    const required = formItem.required;

    if (formItem.name === UserItem.DisplayName && !this.state.user.displayName && this.state.require.includes(Require.username)) {
      return (
        <Form.Item
          name="displayName"
          key="displayName"
          label={i18next.t("general:Display name")}
          required={required}
          validateStatus={this.props.errorDisplayName || this.state.errorDisplayName ? "error" : ""}
          help={this.props.errorDisplayName || this.state.errorDisplayName}
          rules={[
            {
              required: required,
              validator: (_, value) => {
                if (value && value.length > 0) {
                  this.setState({errorDisplayName: ""});
                  return Promise.resolve();
                } else {
                  this.setState({errorDisplayName: i18next.t("account:Please input your display name!")});
                  return Promise.reject();
                }
              },
            },
          ]}
        >
          <Input value={this.state.user.displayName} />
        </Form.Item>
      );
    } else if (formItem.name === UserItem.FirstName && !this.state.user.firstName && this.state.require.includes(Require.first_name)) {
      return (
        <Form.Item
          name="firstName"
          key="firstName"
          label={i18next.t("general:First name")}
          required={required}
          rules={[
            {
              required: required,
              message: i18next.t("account:Please input your first name!"),
              whitespace: true,
            },
          ]}
        >
          <Input value={this.state.user.firstName} />
        </Form.Item>
      );
    } else if (formItem.name === UserItem.LastName && !this.state.user.lastName && this.state.require.includes(Require.last_name)) {
      return (
        <Form.Item
          name="lastName"
          key="lastName"
          label={i18next.t("general:Last name")}
          required={required}
          rules={[
            {
              required: required,
              message: i18next.t("account:Please input your last name!"),
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      );
    } else if (formItem.name === UserItem.MiddleName && !this.state.user.middleName && this.state.require.includes(Require.middle_name)) {
      return (
        <Form.Item
          name="middleName"
          key="middleName"
          label={i18next.t("general:Middle name")}
          required={required}
          rules={[
            {
              required: required,
              message: i18next.t("account:Please input your middle name!"),
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      );
    } else if (formItem.name === UserItem.Email && !this.state.user.email && this.state.require.includes(Require.email)) {
      return (
        <React.Fragment>
          <Form.Item
            name="email"
            key="email"
            label={i18next.t("general:Email")}
            required={required}
            validateStatus={this.props.errorEmail || this.state.errorEmail ? "error" : ""}
            help={this.props.errorEmail || this.state.errorEmail}
            rules={[
              {
                required: required,
              },
              {
                validator: (_, value) => {
                  if (required && !Setting.isValidEmail(this.state.email)) {
                    this.setState({validEmail: false});
                    this.setState({errorEmail: i18next.t("account:The input is not valid Email!")});
                    return Promise.reject();
                  }

                  this.setState({validEmail: true});
                  this.setState({errorEmail: ""});
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input onChange={e => this.setState({email: e.target.value})} />
          </Form.Item>
          {
            formItem.rule !== "No verification" &&
            <Form.Item
              name="emailCode"
              key="emailCode"
              label={i18next.t("code:Email code")}
              rules={[{
                required: required,
                message: i18next.t("code:Please input your verification code!"),
              }]}
            >
              <SendCodeInput
                disabled={!this.state.validEmail}
                method={VerifyMethod.Reset}
                onButtonClickArgs={[this.state.email, VerifyType.Email, Setting.getApplicationName(application)]}
                application={application}
              />
            </Form.Item>
          }
        </React.Fragment>
      );
    } else if (formItem.name === UserItem.Phone && !this.state.user.phone && this.state.require.includes(Require.phone)) {
      return (
        <React.Fragment>
          <Form.Item
            label={i18next.t("general:Phone")}
            required={required}
            validateStatus={this.props.errorPhone || this.state.errorPhone ? "error" : ""}
            help={this.props.errorPhone || this.state.errorPhone}
          >
            <Input.Group compact>
              <Form.Item
                name="countryCode"
                key="countryCode"
                noStyle
                rules={[
                  {
                    required: required,
                    message: i18next.t("account:Please select your country code!"),
                  },
                ]}
              >
                <CountryCodeSelect
                  style={{width: "35%"}}
                  countryCodes={this.getApplicationObj().organizationObj.countryCodes}
                />
              </Form.Item>
              <Form.Item
                name="phone"
                key="phone"
                dependencies={["countryCode"]}
                noStyle
                required={required}
                rules={[
                  {
                    required: required,
                  },
                  ({getFieldValue}) => ({
                    validator: (_, value) => {
                      if (required && !Setting.isValidPhone(value, getFieldValue("countryCode"))) {
                        this.setState({validPhone: false});
                        this.setState({errorPhone: i18next.t("account:The input is not valid Phone!")});
                        return Promise.reject();
                      }

                      this.setState({validPhone: true});
                      this.setState({errorPhone: ""});
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  style={{width: "65%", textAlign: "left"}}
                  onChange={e => this.setState({phone: e.target.value})}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          {
            formItem.rule !== "No verification" &&
            <Form.Item
              name="phoneCode"
              key="phoneCode"
              label={i18next.t("code:Phone code")}
              rules={[
                {
                  required: required,
                  message: i18next.t("code:Please input your phone verification code!"),
                },
              ]}
            >
              <SendCodeInput
                disabled={!this.state.validPhone}
                method={VerifyMethod.Reset}
                onButtonClickArgs={[this.state.phone, VerifyType.Phone, Setting.getApplicationName(application)]}
                application={application}
                countryCode={this.form.current?.getFieldValue("countryCode")}
              />
            </Form.Item>
          }
        </React.Fragment>
      );
    } else if (formItem.name === UserItem.Agreement && this.state.require.includes(Require.agreement)) {
      return AgreementModal.renderAgreementFormItem(application, required, tailFormItemLayout, this);
    }
  }

  render() {
    const application = this.getApplicationObj();
    if (!application || !this.state.user) {
      return <Spin size="large" style={{marginLeft: "20%", marginTop: "10%"}} />;
    }

    return (
      <React.Fragment>
        <Title level={3}>{i18next.t("extend-profile:Please, fill some information about you")}</Title>
        <Text>{i18next.t("extend-profile:Enter missing data")}</Text>
        <br /><br /><br />
        <Form
          {...formItemLayout}
          ref={this.form}
          name="extend-profile"
          onFinish={(values) => this.onFinish(values)}
          onFinishFailed={(errorInfo) => this.onFailed(errorInfo.values, errorInfo.errorFields, errorInfo.outOfDate)}
          size="large"
          layout={Setting.isMobile() ? "vertical" : "horizontal"}
          style={{width: Setting.isMobile() ? "300px" : "auto", margin: "0 auto"}}
        >
          {
            formItems?.map(formItem => this.renderFormItem(application, formItem))
          }
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" loading={this.props.confirmLoading}>
              {i18next.t("extend-profile:Proceed")}
            </Button>
          </Form.Item>
        </Form>
      </React.Fragment>
    );
  }
}

export default withRouter(ExtendProfileForm);
