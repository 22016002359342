// Copyright 2021 The Casdoor Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React, {useState} from "react";
import {Button, Input, Select} from "antd";
import i18next from "i18next";
import * as UserBackend from "../backend/UserBackend";
import {MailOutlined, PhoneOutlined, SafetyOutlined} from "@ant-design/icons";
import * as Setting from "../Setting";
import {CaptchaModal} from "./modal/CaptchaModal";

export const VerifyType = {
  Phone: "phone",
  Email: "email",
};

// Стратегия в рамках которой выполняется верификация номера или email
export const VerifyMethod = {
  Signup: "signup", // Регистрация
  Reset: "reset", // Сброс email, номера
  Login: "login", // Авторизация / Регистрация
  LoginForced: "loginForced", // Авторизация / Регистрация с отключенным переключением получателя подтверждения
  Forget: "forget", // Восстановление пароля
  MfaSetup: "mfaSetup", // Настойка MFA
  MfaAuth: "mfaAuth", // Авторизация через MFA
};

// Изменение способа подтверждения (по факту приходит с бекенда)
const verificationTypeSwitch = {
  phoneToEmail: "phone_to_email", // Код отправлен в Email, хотя подтверждался номер телефона
};

export const SendCodeInput = ({
  value,
  disabled,
  textBefore,
  onChange,
  onButtonClickArgs,
  application,
  method,
  countryCode,
  onSuccess,
}) => {
  const [visible, setVisible] = React.useState(false);
  const [buttonLeftTime, setButtonLeftTime] = React.useState(0);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const verifyTypeSource = VerifyType.Email;
  const [verifyType, setVerifyType] = useState(verifyTypeSource);
  const [verifyMethod, setVerifyMethod] = useState(method);
  const [verifyTypeSwitchVisible, setVerifyTypeSwitchVisible] = useState(false);

  const handleCountDown = (leftTime = 60) => {
    let leftTimeSecond = leftTime;
    setButtonLeftTime(leftTimeSecond);
    const countDown = () => {
      leftTimeSecond--;
      setButtonLeftTime(leftTimeSecond);
      if (leftTimeSecond === 0) {
        return;
      }
      setTimeout(countDown, 1000);
    };
    setTimeout(countDown, 1000);
  };

  const handleOk = (captchaType, captchaToken, clintSecret) => {
    setVisible(false);
    setButtonLoading(true);
    UserBackend.sendCode(captchaType, captchaToken, clintSecret, verifyMethod, countryCode, ...onButtonClickArgs).then(res => {
      setButtonLoading(false);
      if (onSuccess) {
        onSuccess(res.data);
      }
      Setting.showMessage("success", res.data);
      switch (res.data2) {
      case verificationTypeSwitch.phoneToEmail:
        setVerifyTypeSwitchVisible(true);
      }
      handleCountDown(60);
    }).catch(reason => {
      setButtonLoading(false);
      Setting.showMessage("error", reason.message);
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onSwitchVerifyType = (verifyTypeChanged) => {
    setVerifyType(verifyTypeChanged);
    if (verifyTypeSource !== verifyTypeChanged) {
      // Если выбран тип подтверждения отличный от email, установим метод верификации в принудительный
      // Для проверки по SMS
      setVerifyMethod(VerifyMethod.LoginForced);
    } else {
      setVerifyMethod(method);
    }
  };

  return (
    <React.Fragment>
      <Input.Search
        addonBefore={textBefore}
        disabled={disabled}
        value={value}
        prefix={<SafetyOutlined />}
        placeholder={i18next.t("code:Enter your code")}
        onChange={e => onChange(e.target.value)}
        enterButton={
          <Button
            style={{fontSize: 14}}
            type={"primary"}
            disabled={disabled || buttonLeftTime > 0}
            loading={buttonLoading || visible}>
            {buttonLeftTime > 0 ? `${buttonLeftTime} с` : buttonLoading ? i18next.t("code:Sending") : i18next.t("code:Send Code")}
          </Button>
        }
        onSearch={() => !(disabled || buttonLeftTime > 0) && setVisible(true)}
      />
      {
        verifyTypeSwitchVisible &&
        <Select
          title={i18next.t("code:Select verification code destination")}
          virtual={false}
          disabled={disabled || buttonLeftTime > 0}
          value={verifyType}
          onChange={value => onSwitchVerifyType(value)}
        >
          <Select.Option key={VerifyType.Email} value={VerifyType.Email}>
            <MailOutlined className="site-form-item-icon" />&nbsp;{i18next.t("code:Send Email verification code")}
          </Select.Option>
          <Select.Option key={VerifyType.Phone} value={VerifyType.Phone}>
            <PhoneOutlined className="site-form-item-icon" />&nbsp;{i18next.t("code:Send SMS verification code")}
          </Select.Option>
        </Select>
      }
      <CaptchaModal
        owner={application.owner}
        name={application.name}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        isCurrentProvider={false}
      />
    </React.Fragment>
  );
};
