import React from "react";
import {Button, Card, Col, Form, Input, Result, Row} from "antd";
import {withRouter} from "react-router-dom";
import * as UserBackend from "../backend/UserBackend";
import * as OrganizationBackend from "../backend/OrganizationBackend";
import {VerifyType} from "../common/SendCodeInput";
import * as Setting from "../Setting";
import i18next from "i18next";
import CropperDivModal from "../common/modal/CropperDivModal.js";
import * as ApplicationBackend from "../backend/ApplicationBackend";
import ResetModal from "../common/modal/ResetModal";
import {CountryCodeSelect} from "../common/select/CountryCodeSelect";
import AccountAvatar from "../account/AccountAvatar";
import {UserItem} from "../UserItem";

// Компонент страницы профиля пользователя
class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: props,
      organizationName: props.account.owner,
      userName: props.account.name,
      user: null,
      application: null,
      groups: null,
      organizations: [],
      applications: [],
      loading: true,
      confirmLoading: false,
      redirectUrl: null, // Url куда выполнять редирект
      errorDisplayName: "",
    };
  }

  UNSAFE_componentWillMount() {
    this.getUser();
    this.getOrganizations();
    this.getApplicationsByOrganization(this.state.organizationName);
    this.getUserApplication();
    this.setQueryParams();
  }

  getUser() {
    UserBackend.getUser(this.state.organizationName, this.state.userName)
      .then((res) => {
        if (res.data === null) {
          this.props.history.push("/404");
          return;
        }

        if (res.status === "error") {
          Setting.showMessage("error", res.msg);
          return;
        }

        this.setState({
          user: res.data,
          loading: false,
        });
      });
  }

  getOrganizations() {
    OrganizationBackend.getOrganizations("admin")
      .then((res) => {
        this.setState({
          organizations: res.data || [],
        });
      });
  }

  getApplicationsByOrganization(organizationName) {
    ApplicationBackend.getApplicationsByOrganization("admin", organizationName)
      .then((res) => {
        this.setState({
          applications: res.data || [],
        });
      });
  }

  getUserApplication() {
    ApplicationBackend.getUserApplication(this.state.organizationName, this.state.userName)
      .then((res) => {
        if (res.status === "error") {
          Setting.showMessage("error", res.msg);
          return;
        }

        this.setState({
          application: res.data,
        });
        this.props.onUpdateApplication(res.data);
      });
  }

  getUserOrganization() {
    return this.state.application?.organizationObj;
  }

  setQueryParams() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const redirectUrl = searchParams.get("redirectUrl");
    if (redirectUrl !== null) {
      this.setState({redirectUrl});
    }
  }

  parseUserField(key, value) {
    return value;
  }

  updateUserField(key, value) {
    value = this.parseUserField(key, value);

    const user = this.state.user;
    user[key] = value;
    this.setState({
      user: user,
    });
  }

  isSelf() {
    return (this.state.user.id === this.props.account?.id);
  }

  isSelfOrAdmin() {
    return this.isSelf() || Setting.isAdminUser(this.props.account);
  }

  getCountryCode() {
    return this.props.account.countryCode;
  }

  goToPreviousPage() {
    this.setState({confirmLoading: true});
    window.location.href = this.state.redirectUrl;
  }

  renderAccountItem(accountItem) {
    if (!accountItem.visible) {
      return null;
    }

    const isAdmin = Setting.isAdminUser(this.props.account);

    if (accountItem.viewRule === "Self") {
      if (!this.isSelfOrAdmin()) {
        return null;
      }
    } else if (accountItem.viewRule === "Admin") {
      if (!isAdmin) {
        return null;
      }
    }

    let disabled = false;
    if (accountItem.modifyRule === "Self") {
      if (!this.isSelfOrAdmin()) {
        disabled = true;
      }
    } else if (accountItem.modifyRule === "Admin") {
      if (!isAdmin) {
        disabled = true;
      }
    } else if (accountItem.modifyRule === "Immutable") {
      disabled = true;
    }

    if (accountItem.name === "Organization" || accountItem.name === "Name") {
      if (this.state.user.owner === "built-in" && this.state.user.name === "admin") {
        disabled = true;
      }
    }

    const labelSpan = (Setting.isMobile()) ? 24 : 3;
    const inputSpan = (Setting.isMobile()) ? 24 : 21;

    if (accountItem.name === UserItem.DisplayName) {
      return (
        <Row style={{marginTop: "20px"}} >
          <Col style={{marginTop: "5px"}} span={labelSpan}>
            {Setting.getLabel(i18next.t("general:Display name"), i18next.t("general:Display name - Tooltip"))} :
          </Col>
          <Col span={inputSpan} >
            <Form.Item
              validateStatus={this.state.errorDisplayName ? "error" : ""}
              help={this.state.errorDisplayName}
              rules={[
                {
                  required: true,
                  message: i18next.t("account:Please input your display name!"),
                  whitespace: true,
                },
              ]}
            >
              <Input value={this.state.user.displayName} onChange={e => {
                this.updateUserField("displayName", e.target.value);
              }} />
            </Form.Item>
          </Col>
        </Row>
      );
    } else if (accountItem.name === UserItem.FirstName) {
      return (
        <Row style={{marginTop: "20px"}} >
          <Col style={{marginTop: "5px"}} span={labelSpan}>
            {Setting.getLabel(i18next.t("general:First name"), i18next.t("general:First name - Tooltip"))} :
          </Col>
          <Col span={inputSpan} >
            <Input value={this.state.user.firstName} onChange={e => {
              this.updateUserField("firstName", e.target.value);
            }} />
          </Col>
        </Row>
      );
    } else if (accountItem.name === UserItem.LastName) {
      return (
        <Row style={{marginTop: "20px"}} >
          <Col style={{marginTop: "5px"}} span={labelSpan}>
            {Setting.getLabel(i18next.t("general:Last name"), i18next.t("general:Last name - Tooltip"))} :
          </Col>
          <Col span={inputSpan} >
            <Input value={this.state.user.lastName} onChange={e => {
              this.updateUserField("lastName", e.target.value);
            }} />
          </Col>
        </Row>
      );
    } else if (accountItem.name === UserItem.MiddleName) {
      return (
        <Row style={{marginTop: "20px"}} >
          <Col style={{marginTop: "5px"}} span={labelSpan}>
            {Setting.getLabel(i18next.t("general:Middle name"), i18next.t("general:Middle name - Tooltip"))} :
          </Col>
          <Col span={inputSpan} >
            <Input value={this.state.user.middleName} onChange={e => {
              this.updateUserField("middleName", e.target.value);
            }} />
          </Col>
        </Row>
      );
    } else if (accountItem.name === UserItem.Avatar) {
      return (
        <Row style={{marginTop: "20px"}} >
          <Col style={{marginTop: "5px"}} span={labelSpan}>
            {Setting.getLabel(i18next.t("general:Avatar"), i18next.t("general:Avatar - Tooltip"))} :
          </Col>
          <Col>
            {this.renderImage(this.state.user.avatar, i18next.t("user:Upload a photo"), i18next.t("user:Set new profile picture"), "avatar", false)}
          </Col>
        </Row>
      );
    } else if (accountItem.name === UserItem.Email) {
      return (
        <Row style={{marginTop: "20px"}} >
          <Col style={{marginTop: "5px"}} span={labelSpan}>
            {Setting.getLabel(i18next.t("general:Email"), i18next.t("general:Email - Tooltip"))} :
          </Col>
          <Col style={{paddingRight: "20px"}} span={9} >
            <Input
              value={this.state.user.email}
              style={{width: "280Px"}}
              disabled={!Setting.isLocalAdminUser(this.props.account) ? true : disabled}
              onChange={e => {
                this.updateUserField("email", e.target.value);
              }}
            />
          </Col>
          <Col span={Setting.isMobile() ? 22 : 9} >
            {/* backend auto get the current user, so admin can not edit. Just self can reset*/}
            {this.isSelf() ? <ResetModal application={this.state.application} disabled={disabled} buttonText={i18next.t("user:Reset Email...")} destType={VerifyType.Email} countryCodes={this.getUserOrganization()?.countryCodes} /> : null}
          </Col>
        </Row>
      );
    } else if (accountItem.name === UserItem.Phone) {
      return (
        <Row style={{marginTop: "20px"}} >
          <Col style={{marginTop: "5px"}} span={labelSpan}>
            {Setting.getLabel(i18next.t("general:Phone"), i18next.t("general:Phone - Tooltip"))} :
          </Col>
          <Col style={{paddingRight: "20px"}} span={9} >
            <Input.Group compact style={{width: "280Px"}}>
              <CountryCodeSelect
                style={{width: "30%"}}
                disabled={!Setting.isLocalAdminUser(this.props.account) ? true : disabled}
                initValue={this.state.user.countryCode}
                onChange={(value) => {
                  this.updateUserField("countryCode", value);
                }}
                countryCodes={this.getUserOrganization()?.countryCodes}
              />
              <Input value={this.state.user.phone}
                style={{width: "70%"}}
                disabled={!Setting.isLocalAdminUser(this.props.account) ? true : disabled}
                onChange={e => {
                  this.updateUserField("phone", e.target.value);
                }} />
            </Input.Group>
          </Col>
          <Col span={Setting.isMobile() ? 24 : 9} >
            {this.isSelf() ? (<ResetModal application={this.state.application} countryCode={this.getCountryCode()} disabled={disabled} buttonText={i18next.t("user:Reset Phone...")} destType={VerifyType.Phone} countryCodes={this.getUserOrganization()?.countryCodes} />) : null}
          </Col>
        </Row>
      );
    }
  }

  renderImage(imgUrl, title, set, tag, disabled) {
    return (
      <Col span={4} style={{textAlign: "center", margin: "auto"}} key={tag}>
        {
          imgUrl ?
            <div style={{marginBottom: "10px"}}>
              <AccountAvatar src={imgUrl} alt={imgUrl} height={150} />
            </div>
            :
            <Col style={{height: "78%", border: "1px dotted grey", borderRadius: 3, marginBottom: "10px"}}>
              <div style={{fontSize: 30, margin: 10}}>+</div>
              <div style={{verticalAlign: "middle", marginBottom: 10}}>{`Upload ${title}...`}</div>
            </Col>
        }
        <CropperDivModal disabled={disabled} tag={tag} setTitle={set} buttonText={`${title}...`} title={title} user={this.state.user} organization={this.state.organizations.find(organization => organization.name === this.state.organizationName)} />
      </Col>
    );
  }

  renderUser() {
    return (
      <Card size="small" title={
        <div>
          {i18next.t("account:My Account")}
        </div>
      } style={(Setting.isMobile()) ? {margin: "5px"} : {}} type="inner">
        {
          this.getUserOrganization()?.accountItems?.map(accountItem => {
            return (
              <React.Fragment key={accountItem.name}>
                {
                  this.renderAccountItem(accountItem)
                }
              </React.Fragment>
            );
          })
        }
      </Card>
    );
  }

  submitUserEdit(needExit) {
    this.setState({confirmLoading: true});
    this.setState({errorDisplayName: ""});
    UserBackend.updateUser(this.state.organizationName, this.state.userName, this.state.user)
      .then((res) => {
        this.setState({confirmLoading: false});
        if (res.status === "ok") {
          Setting.showMessage("success", i18next.t("general:Successfully saved"));
          this.setState({
            organizationName: this.state.user.owner,
            userName: this.state.user.name,
          });

          if (this.state.redirectUrl) {
            if (needExit) {
              this.setState({confirmLoading: true});
              window.location.href = this.state.redirectUrl;
            }
          }
        } else {
          switch (res.data) {
          case UserItem.DisplayName:
            this.setState({errorDisplayName: res.msg});
            break;
          }
          Setting.showMessage("error", `${i18next.t("general:Failed to save")}: ${res.msg}`);
          this.updateUserField("owner", this.state.organizationName);
          this.updateUserField("name", this.state.userName);
        }
      })
      .catch(error => {
        Setting.showMessage("error", `${i18next.t("general:Failed to connect to server")}: ${error}`);
      });
  }

  render() {
    return (
      this.state.loading ? null : (
        <div style={{flex: "auto", alignSelf: "flex-start"}}>
          {this.state.application?.logo && (
            <img src={this.state.application.logo} alt={this.state.application.logo} style={{margin: "10px 15px", textAlign: "center", height: "65px"}} />
          )}
          {
            this.state.user !== null ? this.renderUser() :
              <Result
                status="404"
                title="404 NOT FOUND"
                subTitle={i18next.t("general:Sorry, the user you visited does not exist or you are not authorized to access this user.")}
                extra={<a href="/"><Button type="primary">{i18next.t("general:Back Home")}</Button></a>}
              />
          }
          {
            this.state.user === null ? null :
              <div style={{marginTop: "20px", marginLeft: "20px"}}>
                <Button loading={this.state.confirmLoading} size="large" onClick={() => this.goToPreviousPage()}>{i18next.t("general:Back")}</Button>
                <Button loading={this.state.confirmLoading} style={{marginLeft: "20px"}} size="large" onClick={() => this.submitUserEdit(false)}>{i18next.t("general:Save")}</Button>
                <Button loading={this.state.confirmLoading} style={{marginLeft: "20px"}} type="primary" size="large" onClick={() => this.submitUserEdit(true)}>{i18next.t("general:Save & Back")}</Button>
              </div>
          }
        </div>
      )
    );
  }
}

export default withRouter(ProfilePage);
