// Список свойств пользователя
export const UserItem = {
  DisplayName: "Display name", // Имя пользователя отображаемое
  FirstName: "First Name", // Имя
  LastName: "Last Name", // Фамилия
  MiddleName: "Middle Name", // Отчество
  Avatar: "Avatar", // Картинка профиля
  Email: "Email", // Email
  Phone: "Phone", // Номер телефона (без кода страны)
  Agreement: "Agreement", // Пользовательское соглашение
};

// Набор обязательных параметров которые приложение запрашивает при регистрации в системе.
export const Require = {
  phone: "phone",
  email: "email",
  username: "username",
  first_name: "first_name",
  last_name: "last_name",
  middle_name: "middle_name",
  agreement: "agreement",
};
