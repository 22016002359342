import React from "react";
import {Button, Col, Form, Input, Result, Row, Spin, Tabs, Typography} from "antd";
import {ArrowLeftOutlined, LockOutlined, MailOutlined, PhoneOutlined, UserOutlined} from "@ant-design/icons";
import {withRouter} from "react-router-dom";
import * as UserWebauthnBackend from "../backend/UserWebauthnBackend";
import OrganizationSelect from "../common/select/OrganizationSelect";
import * as Conf from "../Conf";
import * as AuthBackend from "./AuthBackend";
import * as OrganizationBackend from "../backend/OrganizationBackend";
import * as ApplicationBackend from "../backend/ApplicationBackend";
import * as Provider from "./Provider";
import * as ProviderButton from "./ProviderButton";
import * as Util from "./Util";
import * as Setting from "../Setting";
import * as AgreementModal from "../common/modal/AgreementModal";
import SelfLoginButton from "./SelfLoginButton";
import i18next from "i18next";
import CustomGithubCorner from "../common/CustomGithubCorner";
import {SendCodeInput, VerifyMethod, VerifyType} from "../common/SendCodeInput";
import LanguageSelect from "../common/select/LanguageSelect";
import {CaptchaModal} from "../common/modal/CaptchaModal";
import {CaptchaRule} from "../common/modal/CaptchaModal";
import RedirectForm from "../common/RedirectForm";
import {MfaAuthVerifyForm, NextMfa, RequiredMfa} from "./mfa/MfaAuthVerifyForm";
import {GoogleOneTapLoginVirtualButton} from "./GoogleLoginButton";
import {CountryCodeSelect} from "../common/select/CountryCodeSelect";
import {ExtendProfileAuth, NextExtendProfile} from "../profile/ExtendProfileAuth";

// Методы авторизации
export const LoginMethod = {
  MPassword: "password", // По паролю
  MPhoneCode: "phoneCode", // По коду с телефона
  MEmailCode: "emailCode", // По коду из email
  MWebAuthn: "webAuthn", // WebAuthn
};

// Желательный метод авторизации
export const PreferredMethod = {
  PMEmail: "email", // По коду из email
  PMPassword: "password", // По паролю
};

// Режим работы
export const LoginMode = {
  ModeSignup: "signup", // Регистрация
  ModeSignin: "signin", // Авторизация
};

// Тип авторизации
export const LoginType = {
  TypeLogin: "login", // Простой вход в систему (В сам сервер Casdoor)
  TypeCode: "code", // OAuth2 c кодом
  TypeSAML: "saml", // SAML
  TypeCAS: "cas", // CAS
};

// Компонент для авторизации / автоматической регистрации пользователей
class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: props,
      type: props.type, // LoginType
      applicationName: props.applicationName ?? (props.match?.params?.applicationName ?? null),
      owner: props.owner ?? (props.match?.params?.owner ?? null),
      mode: props.mode ?? (props.match?.params?.mode ?? null), // LoginMode
      msg: null,
      username: null,
      phone: null,
      validPhone: false,
      email: null,
      validEmail: false,
      enableCaptchaModal: CaptchaRule.Never,
      openCaptchaModal: false,
      verifyCaptcha: undefined,
      samlResponse: "",
      relayState: "",
      redirectUrl: "",
      isTermsOfUseVisible: false,
      termsOfUseContent: "",
      orgChoiceMode: new URLSearchParams(props.location?.search).get("orgChoiceMode") ?? null,
      require: [], // Набор обязательных параметров которые приложение запрашивает при регистрации в системе
      preferred: null, // PreferredMethod - Желательный метод авторизации
      loading: false, // Признак загрузки (Действий пользователя не требуется)
      sendCodeSuccessMessage: "", // Сообщение о том куда был направлен код подтверждения
    };

    if (this.state.type === LoginType.TypeCAS && props.match?.params.casApplicationName !== undefined) {
      this.state.owner = props.match?.params?.owner;
      this.state.applicationName = props.match?.params?.casApplicationName;
    }

    this.form = React.createRef();
  }

  componentDidMount() {
    this.setQueryParams();
    if (this.getApplicationObj() === undefined) {
      if (this.state.type === LoginType.TypeLogin || this.state.type === LoginType.TypeSAML) {
        this.getApplication();
      } else if (this.state.type === LoginType.TypeCode || this.state.type === LoginType.TypeCAS) {
        this.getApplicationLogin();
      } else {
        Setting.showMessage("error", `Unknown authentication type: ${this.state.type}`);
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.loginMethod === undefined && this.state.loginMethod === undefined) {
      const application = this.getApplicationObj();
      this.setState({loginMethod: this.getDefaultLoginMethod(application)});
    }
    if (prevProps.application !== this.props.application) {
      this.setState({loginMethod: this.getDefaultLoginMethod(this.props.application)});

      const captchaProviderItems = this.getCaptchaProviderItems(this.props.application);
      if (captchaProviderItems) {
        if (captchaProviderItems.some(providerItem => providerItem.rule === "Always")) {
          this.setState({enableCaptchaModal: CaptchaRule.Always});
        } else if (captchaProviderItems.some(providerItem => providerItem.rule === "Dynamic")) {
          this.setState({enableCaptchaModal: CaptchaRule.Dynamic});
        } else {
          this.setState({enableCaptchaModal: CaptchaRule.Never});
        }
      }

      if (this.props.account && this.props.account.owner === this.props.application?.organization) {
        const params = new URLSearchParams(this.props.location.search);
        const silentSignin = params.get("silentSignin");
        if (silentSignin !== null) {
          this.sendSilentSigninData("signing-in");

          const values = {};
          values["application"] = this.props.application.name;
          this.login(values);
        }

        if (params.get("popup") === "1") {
          window.addEventListener("beforeunload", () => {
            this.sendPopupData({type: "windowClosed"}, params.get("redirect_uri"));
          });
        }

        if (this.props.application.enableAutoSignin) {
          const values = {};
          values["application"] = this.props.application.name;
          this.login(values);
        }
      }
    }
  }

  // Парсинг URL параметров адресной строки
  setQueryParams() {
    const searchParams = new URLSearchParams(this.props.location.search);
    let require = searchParams.get("require");
    if (require) {
      require = require.split(" ");
      this.setState({require});
    }
    const preferred = searchParams.get("preferred");
    if (preferred) {
      this.setState({preferred});
    }
  }

  // Проверка необходимости открыть окно с капчей
  checkCaptchaStatus(values) {
    AuthBackend.getCaptchaStatus(values)
      .then((res) => {
        if (res.status === "ok") {
          if (res.data) {
            // Открываем окно с капчей
            this.setState({
              openCaptchaModal: true,
              values: values,
            });
            return null;
          }
        }
        this.login(values);
      });
  }

  getApplicationLogin() {
    const loginParams = (this.state.type === LoginType.TypeCAS) ? Util.getCasLoginParameters("admin", this.state.applicationName) : Util.getOAuthGetParameters();
    AuthBackend.getApplicationLogin(loginParams)
      .then((res) => {
        if (res.status === "ok") {
          const application = res.data;
          this.onUpdateApplication(application);
        } else {
          this.onUpdateApplication(null);
          this.setState({
            msg: res.msg,
          });
        }
      });
  }

  getApplication() {
    if (this.state.applicationName === null) {
      return null;
    }

    if (this.state.owner === null || this.state.type === LoginType.TypeSAML) {
      ApplicationBackend.getApplication("admin", this.state.applicationName)
        .then((res) => {
          if (res.status === "error") {
            this.onUpdateApplication(null);
            this.setState({
              msg: res.msg,
            });
            return ;
          }
          this.onUpdateApplication(res.data);
        });
    } else {
      OrganizationBackend.getDefaultApplication("admin", this.state.owner)
        .then((res) => {
          if (res.status === "ok") {
            const application = res.data;
            this.onUpdateApplication(application);
            this.setState({
              applicationName: res.data.name,
            });
          } else {
            this.onUpdateApplication(null);
            Setting.showMessage("error", res.msg);

            this.props.history.push("/404");
          }
        });
    }
  }

  getApplicationObj() {
    return this.props.application;
  }

  getDefaultLoginMethod(application) {
    // Если желаемый метод авторизации передан в параметрах, то используем его
    if (application?.enableCodeEmailSignin && this.state.preferred === PreferredMethod.PMEmail) {
      return LoginMethod.MEmailCode;
    }
    if (this.state.preferred === PreferredMethod.PMPassword) {
      return LoginMethod.MPassword;
    }
    if (application?.enablePassword) {
      return LoginMethod.MPassword;
    }
    if (application?.enableCodeSignin) {
      return LoginMethod.MPhoneCode;
    }
    if (application?.enableCodeEmailSignin) {
      return LoginMethod.MEmailCode;
    }
    if (application?.enableWebAuthn) {
      return LoginMethod.MWebAuthn;
    }

    return LoginMethod.MPassword;
  }

  onUpdateAccount(account) {
    this.props.onUpdateAccount(account);
  }

  onUpdateApplication(application) {
    this.props.onUpdateApplication(application);
  }

  parseOffset(offset) {
    if (offset === 2 || offset === 4 || Setting.inIframe() || Setting.isMobile()) {
      return "0 auto";
    }
    if (offset === 1) {
      return "0 10%";
    }
    if (offset === 3) {
      return "0 60%";
    }
  }

  populateOauthValues(values) {
    if (this.getApplicationObj()?.organization) {
      values["organization"] = this.getApplicationObj().organization;
    }

    const oAuthParams = Util.getOAuthGetParameters();

    values["type"] = oAuthParams?.responseType ?? this.state.type;

    if (oAuthParams?.samlRequest) {
      values["samlRequest"] = oAuthParams.samlRequest;
      values["type"] = "saml";
      values["relayState"] = oAuthParams.relayState;
    }
  }

  sendPopupData(message, redirectUri) {
    const params = new URLSearchParams(this.props.location.search);
    if (params.get("popup") === "1") {
      window.opener.postMessage(message, redirectUri);
    }
  }

  postCodeLoginAction(resp) {
    const application = this.getApplicationObj();
    const ths = this;
    const oAuthParams = Util.getOAuthGetParameters();
    const code = resp.data;
    const concatChar = oAuthParams?.redirectUri?.includes("?") ? "&" : "?";
    const noRedirect = oAuthParams.noRedirect;
    const embed = oAuthParams.embed;
    const redirectUrl = `${oAuthParams.redirectUri}${concatChar}code=${code}&state=${oAuthParams.state}`;
    if (resp.data === RequiredMfa) {
      this.props.onLoginSuccess(window.location.href);
      return;
    }

    if (Setting.hasPromptPage(application)) {
      AuthBackend.getAccount()
        .then((res) => {
          if (res.status === "ok") {
            const account = res.data;
            account.organization = res.data2;
            this.onUpdateAccount(account);

            if (Setting.isPromptAnswered(account, application)) {
              Setting.goToLink(redirectUrl);
            } else {
              Setting.goToLinkSoft(ths, `/prompt/${application.name}?redirectUri=${oAuthParams.redirectUri}&code=${code}&state=${oAuthParams.state}`);
            }
          } else {
            Setting.showMessage("error", `${i18next.t("application:Failed to sign in")}: ${res.msg}`);
          }
        });
    } else {
      if (noRedirect === "true") {
        window.close();
        const newWindow = window.open(redirectUrl);
        if (newWindow) {
          setInterval(() => {
            if (!newWindow.closed) {
              newWindow.close();
            }
          }, 1000);
        }
      } else if (embed === "true") {
        this.setState({loading: true});
        this.sendIFrameLoginData(redirectUrl);
      } else {
        this.setState({loading: true});
        Setting.goToLink(redirectUrl);
        this.sendPopupData({type: "loginSuccess", data: {code: code, state: oAuthParams.state}}, oAuthParams.redirectUri);
      }
    }
  }

  onFinish(values) {
    if (this.state.loginMethod === LoginMethod.MWebAuthn) {
      let username = this.state.username;
      if (username === null || username === "") {
        username = values["username"];
      }

      this.signInWithWebAuthn(username, values);
      return;
    }
    if (this.state.loginMethod === LoginMethod.MPassword) {
      if (this.state.enableCaptchaModal === CaptchaRule.Always) {
        this.setState({
          openCaptchaModal: true,
          values: values,
        });
        return;
      } else if (this.state.enableCaptchaModal === CaptchaRule.Dynamic) {
        this.checkCaptchaStatus(values);
        return;
      }
    }
    this.login(values);
  }

  login(values) {
    values.autoSignin = true;
    values.requiredFields = this.state.require;
    // here we are supposed to determine whether Casdoor is working as an OAuth server or CAS server
    if (this.state.type === LoginType.TypeCAS) {
      // CAS
      const casParams = Util.getCasParameters();
      values["type"] = this.state.type;
      AuthBackend.loginCas(values, casParams).then((res) => {
        if (res.status === "ok") {
          let msg = "Logged in successfully. ";
          if (casParams.service === "") {
            // If service was not specified, Casdoor must display a message notifying the client that it has successfully initiated a single sign-on session.
            msg += "Now you can visit apps protected by Casdoor.";
          }
          Setting.showMessage("success", msg);

          if (casParams.service !== "") {
            const st = res.data;
            const newUrl = new URL(casParams.service);
            newUrl.searchParams.append("ticket", st);
            window.location.href = newUrl.toString();
          }
        } else {
          Setting.showMessage("error", `${i18next.t("application:Failed to sign in")}: ${res.msg}`);
        }
      });
    } else {
      // OAuth
      const oAuthParams = Util.getOAuthGetParameters();
      this.populateOauthValues(values);
      this.setState({loading: true});
      AuthBackend.login(values, oAuthParams)
        .then((res) => {
          this.setState({loading: false});
          const callback = (res) => {
            const responseType = values["type"];

            if (responseType === "login") {
              Setting.showMessage("success", i18next.t("application:Logged in successfully"));
              this.props.onLoginSuccess();
            } else if (responseType === "code") {
              this.postCodeLoginAction(res);
            } else if (responseType === "token" || responseType === "id_token") {
              const amendatoryResponseType = responseType === "token" ? "access_token" : responseType;
              const accessToken = res.data;
              Setting.goToLink(`${oAuthParams.redirectUri}#${amendatoryResponseType}=${accessToken}&state=${oAuthParams.state}&token_type=bearer`);
            } else if (responseType === "saml") {
              if (res.data2.method === "POST") {
                this.setState({
                  samlResponse: res.data,
                  redirectUrl: res.data2.redirectUrl,
                  relayState: oAuthParams.relayState,
                });
              } else {
                const SAMLResponse = res.data;
                const redirectUri = res.data2.redirectUrl;
                Setting.goToLink(`${redirectUri}?SAMLResponse=${encodeURIComponent(SAMLResponse)}&RelayState=${oAuthParams.relayState}`);
              }
            }
          };

          if (res.status === "ok") {
            if (res.data === NextMfa) {
              this.setState({
                getVerifyTotp: () => {
                  return (
                    <MfaAuthVerifyForm
                      mfaProps={res.data2}
                      formValues={values}
                      oAuthParams={oAuthParams}
                      application={this.getApplicationObj()}
                      onFail={() => {
                        Setting.showMessage("error", i18next.t("mfa:Verification failed"));
                      }}
                      onSuccess={(res) => callback(res)}
                    />);
                },
              });
            } else if (res.data === NextExtendProfile) {
              // Переходим на шаг до заполнения данных
              this.setState({
                getExtendProfile: () => {
                  return (
                    <ExtendProfileAuth
                      extendData={res.data2}
                      formValues={values}
                      oAuthParams={oAuthParams}
                      application={this.getApplicationObj()}
                      require={this.state.require}
                      loading={this.state.loading}
                      onFail={(msg) => {
                        Setting.showMessage("error", `${i18next.t("application:Failed to sign in")}: ${msg}`);
                      }}
                      onSuccess={(res) => callback(res)}
                    />);
                },
              });
            } else if (res.data === "SelectPlan") {
              // paid-user does not have active or pending subscription, go to application default pricing page to select-plan
              const pricing = res.data2;
              Setting.goToLink(`/select-plan/${pricing.owner}/${pricing.name}?user=${values.username}`);
            } else if (res.data === "BuyPlanResult") {
              // paid-user has pending subscription, go to buy-plan/result apge to notify payment result
              const sub = res.data2;
              Setting.goToLink(`/buy-plan/${sub.owner}/${sub.pricing}/result?subscription=${sub.name}`);
            } else {
              callback(res);
            }
          } else {
            Setting.showMessage("error", `${i18next.t("application:Failed to sign in")}: ${res.msg}`);
          }
        });
    }
  }

  isProviderVisible(providerItem) {
    if (this.state.mode === LoginMode.ModeSignup) {
      return Setting.isProviderVisibleForSignUp(providerItem);
    } else {
      return Setting.isProviderVisibleForSignIn(providerItem);
    }
  }

  renderOtherFormProvider(application) {
    for (const providerConf of application.providers) {
      if (providerConf.provider?.type === "Google" && providerConf.rule === "OneTap" && this.props.preview !== "auto") {
        return (
          <GoogleOneTapLoginVirtualButton application={application} providerConf={providerConf} />
        );
      }
    }
  }

  renderForm(application) {
    if (this.state.msg !== null) {
      return Util.renderMessage(this.state.msg);
    }

    if (this.state.mode === LoginMode.ModeSignup && !application.enableSignUp) {
      return (
        <Result
          status="error"
          title={i18next.t("application:Sign Up Error")}
          subTitle={i18next.t("application:The application does not allow to sign up new account")}
          extra={[
            <Button type="primary" key="signin"
              onClick={() => Setting.redirectToLoginPage(application, this.props.history)}>
              {
                i18next.t("login:Sign In")
              }
            </Button>,
          ]}
        >
        </Result>
      );
    }

    const showForm = application.enablePassword || application.enableCodeSignin || application.enableCodeEmailSignin || application.enableWebAuthn;
    if (showForm) {
      const loginWidth = 330;

      return (
        <Form
          name="normal_login"
          initialValues={{

            organization: application.organization,
            application: application.name,
            username: Conf.ShowGithubCorner ? "admin" : "",
            password: Conf.ShowGithubCorner ? "123" : "",
          }}
          onFinish={(values) => {
            this.onFinish(values);
          }}
          style={{width: `${loginWidth}px`}}
          size="large"
          ref={this.form}
        >
          <Form.Item
            hidden={true}
            name="application"
            rules={[
              {
                required: true,
                message: i18next.t("application:Please input your application!"),
              },
            ]}
          >
          </Form.Item>
          <Form.Item
            hidden={true}
            name="organization"
            rules={[
              {
                required: true,
                message: i18next.t("application:Please input your organization!"),
              },
            ]}
          >
          </Form.Item>
          {this.renderMethodChoiceBox()}
          <Row style={{minHeight: 130, alignItems: "center"}}>
            {this.renderLoginFields()}
          </Row>
          {AgreementModal.isAgreementRequired(application) ? AgreementModal.renderAgreementFormItem(application, true, {}, this) : null}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={this.state.loading}
              style={{width: "100%", marginBottom: "5px"}}
            >
              {
                this.state.loginMethod === LoginMethod.MWebAuthn ? i18next.t("login:Sign in with WebAuthn") :
                  i18next.t("login:Sign In")
              }
            </Button>
            {
              this.renderCaptchaModal(application)
            }
          </Form.Item>
          <Form.Item>
            {
              application.providers.filter(providerItem => this.isProviderVisible(providerItem)).map(providerItem => {
                return ProviderButton.renderProviderLogo(providerItem.provider, application, 30, 5, "small", this.props.location);
              })
            }
            {
              this.renderOtherFormProvider(application)
            }
          </Form.Item>
        </Form>
      );
    } else {
      return (
        <div style={{marginTop: "20px"}}>
          <div style={{fontSize: 16, textAlign: "left"}}>
            {i18next.t("login:To access")}&nbsp;
            <a target="_blank" rel="noreferrer" href={application.homepageUrl}>
              <span style={{fontWeight: "bold"}}>
                {application.displayName}
              </span>
            </a>
              :
          </div>
          <br />
          {
            application.providers?.filter(providerItem => this.isProviderVisible(providerItem)).map(providerItem => {
              return ProviderButton.renderProviderLogo(providerItem.provider, application, 40, 10, "big", this.props.location);
            })
          }
          {
            this.renderOtherFormProvider(application)
          }
        </div>
      );
    }
  }

  getCaptchaProviderItems(application) {
    const providers = application?.providers;

    if (providers === undefined || providers === null) {
      return null;
    }

    return providers.filter(providerItem => {
      if (providerItem.provider === undefined || providerItem.provider === null) {
        return false;
      }

      return providerItem.provider.category === "Captcha";
    });
  }

  renderCaptchaModal(application) {
    if (this.state.enableCaptchaModal === CaptchaRule.Never) {
      return null;
    }
    const captchaProviderItems = this.getCaptchaProviderItems(application);
    const alwaysProviderItems = captchaProviderItems.filter(providerItem => providerItem.rule === "Always");
    const dynamicProviderItems = captchaProviderItems.filter(providerItem => providerItem.rule === "Dynamic");
    const provider = alwaysProviderItems.length > 0
      ? alwaysProviderItems[0].provider
      : dynamicProviderItems[0].provider;

    return <CaptchaModal
      owner={provider.owner}
      name={provider.name}
      visible={this.state.openCaptchaModal}
      onOk={(captchaType, captchaToken, clientSecret) => {
        const values = this.state.values;
        values["captchaType"] = captchaType;
        values["captchaToken"] = captchaToken;
        values["clientSecret"] = clientSecret;

        this.login(values);
        this.setState({openCaptchaModal: false});
      }}
      onCancel={() => this.setState({openCaptchaModal: false})}
      isCurrentProvider={true}
    />;
  }

  sendSilentSigninData(data) {
    if (Setting.inIframe()) {
      const message = {tag: "Casdoor", type: "SilentSignin", data: data};
      window.parent.postMessage(message, "*");
    }
  }

  sendIFrameLoginData(data) {
    if (Setting.inIframe()) {
      const message = {tag: "Casdoor", type: "Login", data: data};
      window.parent.postMessage(message, "*");
    }
  }

  renderSignedInBox() {
    if (this.props.account === undefined || this.props.account === null) {
      this.sendSilentSigninData("user-not-logged-in");
      return null;
    }

    const application = this.getApplicationObj();
    if (this.props.account.owner !== application?.organization) {
      return null;
    }

    return (
      <div>
        <div style={{fontSize: 16, textAlign: "left"}}>
          {i18next.t("login:Continue with")}&nbsp;:
        </div>
        <br />
        <SelfLoginButton account={this.props.account} onClick={() => {
          const values = {};
          values["application"] = application.name;
          this.login(values);
        }} />
        <br />
        <br />
        <div style={{fontSize: 16, textAlign: "left"}}>
          {i18next.t("login:Or sign in with another account")}&nbsp;:
        </div>
      </div>
    );
  }

  signInWithWebAuthn(username, values) {
    const oAuthParams = Util.getOAuthGetParameters();
    this.populateOauthValues(values);
    const application = this.getApplicationObj();
    return fetch(`${Setting.ServerUrl}/api/webauthn/signin/begin?owner=${application.organization}&name=${username}`, {
      method: "GET",
      credentials: "include",
    })
      .then(res => res.json())
      .then((credentialRequestOptions) => {
        if ("status" in credentialRequestOptions) {
          Setting.showMessage("error", credentialRequestOptions.msg);
          throw credentialRequestOptions.status.msg;
        }

        credentialRequestOptions.publicKey.challenge = UserWebauthnBackend.webAuthnBufferDecode(credentialRequestOptions.publicKey.challenge);
        credentialRequestOptions.publicKey.allowCredentials.forEach(function(listItem) {
          listItem.id = UserWebauthnBackend.webAuthnBufferDecode(listItem.id);
        });

        return navigator.credentials.get({
          publicKey: credentialRequestOptions.publicKey,
        });
      })
      .then((assertion) => {
        const authData = assertion.response.authenticatorData;
        const clientDataJSON = assertion.response.clientDataJSON;
        const rawId = assertion.rawId;
        const sig = assertion.response.signature;
        const userHandle = assertion.response.userHandle;
        return fetch(`${Setting.ServerUrl}/api/webauthn/signin/finish?responseType=${values["type"]}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            id: assertion.id,
            rawId: UserWebauthnBackend.webAuthnBufferEncode(rawId),
            type: assertion.type,
            response: {
              authenticatorData: UserWebauthnBackend.webAuthnBufferEncode(authData),
              clientDataJSON: UserWebauthnBackend.webAuthnBufferEncode(clientDataJSON),
              signature: UserWebauthnBackend.webAuthnBufferEncode(sig),
              userHandle: UserWebauthnBackend.webAuthnBufferEncode(userHandle),
            },
          }),
        })
          .then(res => res.json()).then((res) => {
            if (res.status === "ok") {
              const responseType = values["type"];
              if (responseType === "code") {
                this.postCodeLoginAction(res);
              } else if (responseType === "token" || responseType === "id_token") {
                const accessToken = res.data;
                Setting.goToLink(`${oAuthParams.redirectUri}#${responseType}=${accessToken}?state=${oAuthParams.state}&token_type=bearer`);
              } else {
                Setting.showMessage("success", i18next.t("login:Successfully logged in with WebAuthn credentials"));
                Setting.goToLink("/");
              }
            } else {
              Setting.showMessage("error", res.msg);
            }
          })
          .catch(error => {
            Setting.showMessage("error", `${i18next.t("general:Failed to connect to server")}${error}`);
          });
      });
  }

  // Обработка успешной отправки кода подтверждения
  handleSendCodeSuccess = (message) => {
    this.setState({sendCodeSuccessMessage: message});
  };

  // Рендеринг части формы с сообщением об успешной оправке кода подтверждения
  renderSendCodeSuccess() {
    return (
      <Col span={24}>
        {this.state.sendCodeSuccessMessage && <Typography.Text>{this.state.sendCodeSuccessMessage}</Typography.Text>}
      </Col>
    );
  }

  renderLoginFields() {
    const application = this.getApplicationObj();
    switch (this.state.loginMethod) {
    case LoginMethod.MPassword:
      return (
        <React.Fragment>
          <Col span={24}>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: i18next.t("login:Please input your Username, Email or Phone!"),
                },
              ]}
            >
              <Input
                id="input"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder={i18next.t("login:username, Email or phone")}
                onChange={e => {
                  this.setState({
                    username: e.target.value,
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="password"
              rules={[{required: true, message: i18next.t("login:Please input your password!")}]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder={i18next.t("general:Password")}
                disabled={!(application.enablePassword || this.state.preferred === PreferredMethod.PMPassword)}
              />
            </Form.Item>
          </Col>
          <div style={{display: "inline-flex", justifyContent: "space-between", width: "320px", marginBottom: AgreementModal.isAgreementRequired(application) ? "5px" : "25px"}}>
            {
              Setting.renderForgetLink(application, i18next.t("login:Forgot password?"))
            }
          </div>
        </React.Fragment>
      );

    case LoginMethod.MPhoneCode:
      return (
        <React.Fragment>
          <Col span={24}>
            <Typography.Title level={3}>{i18next.t("login:Input your phone")}</Typography.Title>
            <Typography.Text>{i18next.t("login:We will send verification code. Code maybe arrive at email box or SMS")}</Typography.Text>
          </Col>
          <Col span={24}><br /></Col>
          <Col span={24}>
            <Form.Item>
              <Input.Group compact>
                <Form.Item
                  name="countryCode"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: i18next.t("login:Please select your country code!"),
                    },
                  ]}
                >
                  <CountryCodeSelect
                    style={{width: "35%"}}
                    countryCodes={this.getApplicationObj().organizationObj.countryCodes}
                  />
                </Form.Item>
                <Form.Item
                  name="phone"
                  dependencies={["countryCode"]}
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: i18next.t("login:Please input your Phone!"),
                    },
                    ({getFieldValue}) => ({
                      validator: (_, value) => {
                        if (value && !Setting.isValidPhone(value, getFieldValue("countryCode"))) {
                          this.setState({validPhone: false});
                          return Promise.reject(i18next.t("login:The input is not valid Phone!"));
                        }

                        this.setState({validPhone: true});
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    id="tel-national"
                    style={{width: "65%", textAlign: "left"}}
                    prefix={<PhoneOutlined className="site-form-item-icon" />}
                    placeholder={i18next.t("login:Phone")}
                    autoComplete="tel-national"
                    onChange={e => this.setState({phone: e.target.value})}
                  />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="phoneCode"
              rules={[{required: true, message: i18next.t("login:Please input your code!")}]}
            >
              <SendCodeInput
                disabled={this.state.phone?.length === 0 || !this.state.validPhone}
                method={VerifyMethod.Login}
                onButtonClickArgs={[this.state.phone, VerifyType.Phone, Setting.getApplicationName(application)]}
                application={application}
                onSuccess={this.handleSendCodeSuccess}
                countryCode={this.form.current?.getFieldValue("countryCode")}
              />
            </Form.Item>
          </Col>
          {this.renderSendCodeSuccess()}
        </React.Fragment>
      );

    case LoginMethod.MEmailCode:
      return (
        <React.Fragment>
          <Col span={24}>
            <Typography.Title level={3}>{i18next.t("login:Input your email")}</Typography.Title>
            <Typography.Text>{i18next.t("login:We will send verification code. Code will send to email")}</Typography.Text>
          </Col>
          <Col span={24}><br /></Col>
          <Col span={24}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: i18next.t("login:Please input your Email!"),
                },
                {
                  validator: (_, value) => {
                    if (Setting.isValidEmail(value)) {
                      this.setState({validEmail: true});
                      return Promise.resolve();
                    } else {
                      this.setState({validEmail: false});
                      return Promise.reject(i18next.t("login:The input is not valid Email!"));
                    }
                  },
                },
              ]}
            >
              <Input
                id="email"
                prefix={<MailOutlined className="site-form-item-icon" />}
                placeholder={i18next.t("login:Email")}
                autoComplete="email"
                onChange={e => {
                  this.setState({
                    email: e.target.value,
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="emailCode"
              rules={[{required: true, message: i18next.t("login:Please input your code!")}]}
            >
              <SendCodeInput
                disabled={this.state.email?.length === 0 || !this.state.validEmail}
                method={VerifyMethod.Login}
                onButtonClickArgs={[this.state.email, VerifyType.Email, Setting.getApplicationName(application)]}
                application={application}
                onSuccess={this.handleSendCodeSuccess}
              />
            </Form.Item>
          </Col>
          {this.renderSendCodeSuccess()}
        </React.Fragment>
      );

    case LoginMethod.MWebAuthn:
      return (
        <React.Fragment>
          <Col span={24}>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: i18next.t("login:Please input your Email or Phone!"),
                },
              ]}
            >
              <Input
                id="input"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder={i18next.t("login:username, Email or phone")}
                onChange={e => {
                  this.setState({
                    username: e.target.value,
                  });
                }}
              />
            </Form.Item>
          </Col>
        </React.Fragment>
      );
    }
  }

  renderMethodChoiceBox() {
    const application = this.getApplicationObj();
    const items = [];
    (application.enablePassword || this.state.preferred === PreferredMethod.PMPassword) ? items.push({label: i18next.t("general:Password"), key: LoginMethod.MPassword}) : null;
    application.enableCodeSignin ? items.push({label: i18next.t("login:By phone"), key: LoginMethod.MPhoneCode}) : null;
    application.enableCodeEmailSignin ? items.push({label: i18next.t("login:By Email"), key: LoginMethod.MEmailCode}) : null;
    application.enableWebAuthn ? items.push({label: i18next.t("login:WebAuthn"), key: LoginMethod.MWebAuthn}) : null;

    if (items.length > 1) {
      return (
        <div>
          <Tabs items={items} size={"small"} defaultActiveKey={this.getDefaultLoginMethod(application)} onChange={(key) => {
            this.setState({loginMethod: key});
          }} centered>
          </Tabs>
        </div>
      );
    }
  }

  renderLoginPanel(application) {
    const orgChoiceMode = application.orgChoiceMode;

    if (this.isOrganizationChoiceBoxVisible(orgChoiceMode)) {
      return this.renderOrganizationChoiceBox(orgChoiceMode);
    }

    if (this.state.getVerifyTotp !== undefined) {
      return this.state.getVerifyTotp(); // Рендерим форму 2FA
    } else if (this.state.getExtendProfile !== undefined) {
      return this.state.getExtendProfile(); // Рендерим форму до заполнения данных
    } else {
      return (
        <React.Fragment>
          {this.renderSignedInBox()}
          {this.renderForm(application)}
        </React.Fragment>
      );
    }
  }

  renderOrganizationChoiceBox(orgChoiceMode) {
    const renderChoiceBox = () => {
      switch (orgChoiceMode) {
      case "None":
        return null;
      case "Select":
        return (
          <div>
            <p style={{fontSize: "large"}}>
              {i18next.t("login:Please select an organization to sign in")}
            </p>
            <OrganizationSelect style={{width: "70%"}}
              onSelect={(value) => {
                Setting.goToLink(`/login/${value}?orgChoiceMode=None`);
              }} />
          </div>
        );
      case "Input":
        return (
          <div>
            <p style={{fontSize: "large"}}>
              {i18next.t("login:Please type an organization to sign in")}
            </p>
            <Form
              name="basic"
              onFinish={(values) => {Setting.goToLink(`/login/${values.organizationName}?orgChoiceMode=None`);}}
            >
              <Form.Item
                name="organizationName"
                rules={[{required: true, message: i18next.t("login:Please input your organization name!")}]}
              >
                <Input style={{width: "70%"}} onPressEnter={(e) => {
                  Setting.goToLink(`/login/${e.target.value}?orgChoiceMode=None`);
                }} />
              </Form.Item>
              <Button type="primary" htmlType="submit">
                {i18next.t("general:Confirm")}
              </Button>
            </Form>
          </div>
        );
      default:
        return null;
      }
    };

    return (
      <div style={{height: 300, width: 300}}>
        {renderChoiceBox()}
      </div>
    );
  }

  isOrganizationChoiceBoxVisible(orgChoiceMode) {
    if (this.state.orgChoiceMode === "None") {
      return false;
    }

    const path = this.props.match?.path;
    if (path === "/login" || path === "/login/:owner") {
      return orgChoiceMode === "Select" || orgChoiceMode === "Input";
    }

    return false;
  }

  renderBackButton() {
    if (this.state.orgChoiceMode === "None") {
      return (
        <Button type="text" size="large" icon={<ArrowLeftOutlined />}
          style={{top: "65px", left: "15px", position: "absolute"}}
          onClick={() => history.back()}>
        </Button>
      );
    }
  }

  render() {
    const application = this.getApplicationObj();
    if (application === undefined) {
      return null;
    }
    if (application === null) {
      return Util.renderMessageLarge(this, this.state.msg);
    }

    if (this.state.samlResponse !== "") {
      return <RedirectForm samlResponse={this.state.samlResponse} redirectUrl={this.state.redirectUrl} relayState={this.state.relayState} />;
    }

    if (application.signinHtml !== "") {
      return (
        <div dangerouslySetInnerHTML={{__html: application.signinHtml}} />
      );
    }

    const visibleOAuthProviderItems = (application.providers === null) ? [] : application.providers.filter(providerItem => this.isProviderVisible(providerItem));
    if (this.props.preview !== "auto" && !application.enablePassword && !application.enableCodeSignin && !application.enableCodeEmailSignin && !application.enableWebAuthn && visibleOAuthProviderItems.length === 1) {
      Setting.goToLink(Provider.getAuthUrl(application, visibleOAuthProviderItems[0].provider, "signup"));
      return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
          <Spin size="large" tip={i18next.t("login:Signing in...")} />
        </div>
      );
    }

    return (
      <React.Fragment>
        <CustomGithubCorner />
        <div className="login-content" style={{margin: this.props.preview ?? this.parseOffset(application.formOffset)}}>
          {Setting.inIframe() || Setting.isMobile() ? null : <div dangerouslySetInnerHTML={{__html: application.formCss}} />}
          {Setting.inIframe() || !Setting.isMobile() ? null : <div dangerouslySetInnerHTML={{__html: application.formCssMobile}} />}
          <div className="login-panel">
            <div className="side-image" style={{display: application.formOffset !== 4 ? "none" : null}}>
              <div dangerouslySetInnerHTML={{__html: application.formSideHtml}} />
            </div>
            <div className="login-form">
              <div>
                <div>
                  {
                    Setting.renderHelmet(application)
                  }
                  {
                    Setting.renderLogo(application)
                  }
                  {
                    this.renderBackButton()
                  }
                  <LanguageSelect languages={application.organizationObj.languages} style={{top: "55px", right: "5px", position: "absolute"}} />
                  {
                    this.renderLoginPanel(application)
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(LoginPage);
