import React from "react";
import ExtendProfileForm from "./ExtendProfileForm";
import * as AuthBackend from "../auth/AuthBackend";
import {Require, UserItem} from "../UserItem";

export const NextExtendProfile = "NextExtendProfile";

// Обертка над формой введения дополнительных данных для последнего шага авторизации
export function ExtendProfileAuth({extendData, formValues, oAuthParams, application, require, loading, onSuccess, onFail}) {
  const [confirmLoading, setConfirmLoading] = React.useState(false); // Обработка данных с формы (В процессе)
  const [errorDisplayName, setErrorDisplayName] = React.useState("");
  const [errorEmail, setErrorEmail] = React.useState("");
  const [errorPhone, setErrorPhone] = React.useState("");

  const authData = {
    organization: formValues.organization,
    application: formValues.application,
    autoSignin: formValues.autoSignin,
    type: formValues.type,
  };

  const login = (user) => {
    const values = {...authData, userExtend: user};
    setConfirmLoading(true);
    setErrorDisplayName("");
    setErrorEmail("");
    setErrorPhone("");
    AuthBackend.login(values, oAuthParams).then((res) => {
      setConfirmLoading(false);
      if (res.status === "ok") {
        onSuccess(res);
      } else {
        switch (res.data) {
        case UserItem.DisplayName:
          setErrorDisplayName(res.msg);
          break;
        case UserItem.Email:
          setErrorEmail(res.msg);
          break;
        case UserItem.Phone:
          setErrorPhone(res.msg);
          break;
        }
        onFail(res.msg);
      }
    }).catch((res) => {
      onFail(res.message);
    }).finally(() => {
    });
  };

  if (extendData.agreement) {
    require.push(Require.agreement);
  }

  return (
    <ExtendProfileForm
      accountOwner={extendData.owner}
      accountName={extendData.name}
      application={application}
      require={require}
      confirmLoading={confirmLoading || loading}
      onFinish={(values) => login(values)}
      errorDisplayName={errorDisplayName}
      errorEmail={errorEmail}
      errorPhone={errorPhone}
    />
  );
}
