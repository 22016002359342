import React from "react";
import {Spin} from "antd";
import * as Setting from "../Setting";
import i18next from "i18next";
import * as ApplicationBackend from "../backend/ApplicationBackend";
import LanguageSelect from "../common/select/LanguageSelect";
import {withRouter} from "react-router-dom";
import * as UserBackend from "../backend/UserBackend";
import {UserItem} from "../UserItem";
import ExtendProfileForm from "./ExtendProfileForm";

// Компонент страницы внесения дополнительных данных пользователем
class ExtendProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: props,
      organizationName: props.account.owner,
      userName: props.account.name,
      application: null,
      loading: true, // Загрузка страницы (В процессе)
      confirmLoading: false, // Обработка данных с формы (В процессе)
      redirectUrl: null, // Url куда выполнять редирект
      require: [], // Набор обязательных параметров которые приложение запрашивает при регистрации в системе.
      errorDisplayName: "",
      errorEmail: "",
      errorPhone: "",
    };
  }

  UNSAFE_componentWillMount() {
    this.setQueryParams();
    this.getUserApplication();
  }

  setQueryParams() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const redirectUrl = searchParams.get("redirectUrl");
    if (redirectUrl) {
      this.setState({redirectUrl});
    }
    let require = searchParams.get("require");
    if (require) {
      require = require.split(" ");
      this.setState({require});
    }
  }

  getUserApplication() {
    ApplicationBackend.getUserApplication(this.state.organizationName, this.state.userName)
      .then((res) => {
        if (res.status === "error") {
          Setting.showMessage("error", res.msg);
          return;
        }

        this.setState({
          application: res.data,
          loading: false,
        });
        this.props.onUpdateApplication(res.data);
      });
  }

  getApplicationObj() {
    return this.state.application;
  }

  onUpdateAccount(account) {
    this.props.onUpdateAccount(account);
  }

  onFinish(user) {
    this.setState({confirmLoading: true});
    this.setState({errorDisplayName: ""});
    this.setState({errorEmail: ""});
    this.setState({errorPhone: ""});
    UserBackend.extendProfile(this.state.organizationName, this.state.userName, user)
      .then((res) => {
        this.setState({confirmLoading: false});
        if (res.status === "ok") {
          Setting.showMessage("success", i18next.t("general:Successfully saved"));
          this.setState({
            organizationName: user.owner,
            userName: user.name,
          });

          if (this.state.redirectUrl) {
            this.setState({confirmLoading: true});
            window.location.href = this.state.redirectUrl;
          }
        } else {
          switch (res.data) {
          case UserItem.DisplayName:
            this.setState({errorDisplayName: res.msg});
            break;
          case UserItem.Email:
            this.setState({errorEmail: res.msg});
            break;
          case UserItem.Phone:
            this.setState({errorPhone: res.msg});
            break;
          }
          Setting.showMessage("error", `${i18next.t("general:Failed to save")}: ${res.msg}`);
        }
      })
      .catch(error => {
        Setting.showMessage("error", `${i18next.t("general:Failed to connect to server")}: ${error}`);
      });
  }

  parseOffset(offset) {
    if (offset === 2 || offset === 4 || Setting.inIframe() || Setting.isMobile()) {
      return "0 auto";
    }
    if (offset === 1) {
      return "0 10%";
    }
    if (offset === 3) {
      return "0 60%";
    }
  }

  render() {
    const application = this.getApplicationObj();
    if (application === undefined || application === null) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="login-content" style={{margin: this.props.preview ?? this.parseOffset(application.formOffset)}}>
          {Setting.inIframe() || Setting.isMobile() ? null : <div dangerouslySetInnerHTML={{__html: application.formCss}} />}
          {Setting.inIframe() || !Setting.isMobile() ? null : <div dangerouslySetInnerHTML={{__html: application.formCssMobile}} />}
          <div className="login-panel" >
            <div className="login-form">
              {
                Setting.renderHelmet(application)
              }
              {
                Setting.renderLogo(application)
              }
              <LanguageSelect languages={application.organizationObj.languages} style={{top: "55px", right: "5px", position: "absolute"}} />
              {
                this.state.loading ?
                  <Spin size="large" style={{marginLeft: "20%", marginTop: "10%"}} /> :
                  <ExtendProfileForm
                    accountOwner={this.props.account.owner}
                    accountName={this.props.account.name}
                    application={this.state.application}
                    require={this.state.require}
                    confirmLoading={this.state.confirmLoading}
                    onFinish={(values) => this.onFinish(values)}
                    errorDisplayName={this.state.errorDisplayName}
                    errorEmail={this.state.errorEmail}
                    errorPhone={this.state.errorPhone}
                  />
              }
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ExtendProfilePage);
