// Copyright 2022 The Casdoor Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from "react";
import {Card} from "antd";
import {withRouter} from "react-router-dom";
import * as AuthBackend from "./AuthBackend";
import * as Setting from "../Setting";
import i18next from "i18next";

class CasLogout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: props,
      msg: null,
    };
    if (props.match?.params.casApplicationName !== undefined) {
      this.state.owner = props.match?.params.owner;
      this.state.applicationName = props.match?.params.casApplicationName;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.account !== this.props.account) {
      const params = new URLSearchParams(this.props.location.search);

      AuthBackend.logout()
        .then((res) => {
          if (res.status === "ok") {
            Setting.showMessage("success", i18next.t("application:Logged out successfully"));
            if (params.has("service")) {
              Setting.goToLink(params.get("service"));
            } else {
              this.props.onUpdateAccount(null);
              Setting.goToLinkSoft(this, `/cas/${this.state.owner}/${this.state.applicationName}/login`);
            }
          } else {
            Setting.showMessage("error", `Failed to log out: ${res.msg}`);
          }
        });
    }
  }

  render() {
    return (
      <Card></Card>
    );
  }
}
export default withRouter(CasLogout);
